"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = require("react");

// import
// definition
var _default = function _default() {
  return (0, _react.useCallback)(function () {
    if (window.fbq) window.fbq('track', 'PageView');
    if (window.gtag) window.gtag('config', 'AW-986719315');
  }, []);
};

exports["default"] = _default;