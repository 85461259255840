"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.filter.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.resolvers = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

// typescript import
// graphql typescript
// definition
var resolvers = {
  OrderConnection: {
    pageInfo: function pageInfo(_ref) {
      var _pageInfo = _ref.pageInfo,
          key = _ref.key;
      return _objectSpread(_objectSpread({}, _pageInfo), {}, {
        key: key
      });
    }
  },
  User: {
    ecfitOrders: function ecfitOrders(parent, _, __, info) {
      var _info$field$alias;

      var alias = (info === null || info === void 0 ? void 0 : (_info$field$alias = info.field.alias) === null || _info$field$alias === void 0 ? void 0 : _info$field$alias.value) || 'orders';
      return _objectSpread(_objectSpread({}, parent[alias]), alias === 'orders' ? {
        key: 'ecfit'
      } : null);
    },
    orders: function orders(parent, args) {
      var _args$filter;

      return _objectSpread(_objectSpread({}, parent.orders), !((_args$filter = args.filter) !== null && _args$filter !== void 0 && _args$filter.logisticTrackingStatus) ? null : {
        key: 'ecpay'
      });
    }
  }
};
exports.resolvers = resolvers;