"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GET_STORE_TIMEZONE = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _client = require("@apollo/client");

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  query GetStoreTimezone {\n    viewer {\n      id\n      store {\n        id\n        timezone\n      }\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var GET_STORE_TIMEZONE = (0, _client.gql)(_templateObject());
exports.GET_STORE_TIMEZONE = GET_STORE_TIMEZONE;