// typescript import
import { AppInitialProps } from 'next/app';

// import
import React from 'react';
import NextApp from 'next/app';
import Head from 'next/head';
import Router from 'next/router';
import dynamic from 'next/dynamic';

import '@admin/utils/lib/styles';
import { appWithTranslation } from '@meepshop/locales';
import { AppsProvider } from '@meepshop/context/lib/Apps';
import Switch from '@meepshop/switch';
import AdTrackProvider from '@admin/ad-track';
import CurrencyProvider from '@admin/currency';
import { SiderStateProvider } from '@admin/hooks/lib/useSiderState';
import { TimezoneProvider } from '@admin/hooks/lib/useTimezone';

import { appWithApollo } from '../utils/withApollo';

// definition
const Wrapper = dynamic(() =>
  import(/* webpackChunkName: "Wrapper" */ '@admin/wrapper'),
);

class App extends NextApp<AppInitialProps> {
  public componentDidMount(): void {
    Router.beforePopState(({ as }: { as: string }): boolean => {
      if (!as) window.location.reload();

      return true;
    });
  }

  public render(): React.ReactElement {
    const {
      Component,
      pageProps: { noWrapper, noGTM, ...pageProps },
    } = this.props;

    return (
      <>
        <Head>
          <title>meepShop</title>
          {/* overwrite default viewport meta tag in next.js */}
          <meta name="viewport" content="" />
        </Head>

        <AdTrackProvider noGTM={noGTM}>
          <Switch
            isTrue={!noWrapper}
            render={children => (
              <AppsProvider>
                <TimezoneProvider>
                  <CurrencyProvider>
                    <SiderStateProvider>
                      <Wrapper>{children}</Wrapper>
                    </SiderStateProvider>
                  </CurrencyProvider>
                </TimezoneProvider>
              </AppsProvider>
            )}
          >
            <Component {...pageProps} />
          </Switch>
        </AdTrackProvider>
      </>
    );
  }
}

export default appWithTranslation(appWithApollo(App));
